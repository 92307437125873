@media(prefers-reduced-motion: reduce) {
  :root {
    --hover-transition: all .1ms ease-in-out;
  }
}

:root {
  --font-size: 0.9375rem;
  --box-shadow: none;
  --border-radius: .35rem;
  --background: hsl(210, 12%, 91%);
  --secondary: hsl(210, 15%, 94%);
  --tertiary: hsl(210, 17%, 98%);
  --quaternary: hsl(210, 19%, 99%);
  --disabled: hsla(217, 11%, 14%, .2);
  --hover: hsla(0, 0%, 0%, .1);
  --internal: hsl(210, 12%, 88%);
  --font-color: hsl(207, 11%, 20%);
  --font-color-50: hsla(207, 11%, 20%, 50%);
  --font-color-60: hsla(207, 11%, 20%, 60%);
  --font-color-75: hsla(207, 11%, 20%, 75%);

  --hover-transition: all .2s ease-in-out;
  --hover-transform-scale-lg: scale(1.2);
  --pure-dark-half: hsla(0, 0%, 0%, .5);
}

.dark {
  --box-shadow: none !important;
  --background: hsl(217, 11%, 14%) !important;
  --secondary: hsl(214, 8%, 17%) !important;
  --tertiary: hsl(214, 7%, 19%) !important;
  --quaternary: hsl(213, 7%, 24%) !important;
  --disabled: hsl(243, 17%, 21%) !important;
  --hover: hsl(219, 11%, 32%) !important;
  --internal: hsl(217, 11%, 16%) !important;
  --font-color: hsl(0, 0%, 95%) !important;
  --font-color-50: hsla(0, 0%, 95%, 50%) !important;
  --font-color-60: hsla(0, 0%, 95%, 60%) !important;
  --font-color-75: hsla(0, 0%, 95%, 75%) !important;
}