$font-family-base: Roboto, sans-serif;
$font-size-base: 0.9375rem;
$enable-shadows: true;
$box-shadow: 0 3px 5px 0 hsla(0, 0%, 0%, .15);
$border-width: 0;
$border-color: transparent;
$badge-font-weight: 400;
$badge-font-size: .7rem;
$jumbotron-color: white;
$dropdown-box-shadow: $box-shadow;
$popover-header-bg: hsl(0, 0%, 20%);
$popover-header-color: white;
$link-hover-decoration: none;
$navbar-brand-font-size: 1.25rem;
$input-btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$dropdown-item-padding-y: .15rem;
$dropdown-item-padding-x: .5rem;
$dropdown-font-size: 14px;
$dropdown-min-width: 8.5rem;
$input-box-shadow: $box-shadow;